import React from 'react'
import classes from './PrivateRouter.module.scss'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Header from '../Header/Header'
import ClubColorDefinitions from '../ClubColorDefinitions/ClubColorDefinitions'
import ActivitiesPage from '../../pages/ActivitiesPage/ActivitiesPage'
import ClubsPage from '../../pages/ClubsPage/ClubsPage'
import ClubPage from '../../pages/ClubPage/ClubPage'
import TeamPage from '../../pages/TeamPage/TeamPage'
import StatisticsPage from '../../pages/StatisticsPage/StatisticsPage'
import useMe from '../../customHooks/useMe'

const PrivateRouter = (props) => {
  const {
    headerHeight
  } = props

  const { me } = useMe()

  const renderRouter = () => {
    switch (me.role) {
      case 'admin': {
        return (
          <Switch>
            <Route exact path='/activiteiten' component={ActivitiesPage}/>
            <Route exact path='/clubs' component={ClubsPage}/>
            <Route exact path='/clubs/:clubId' component={ClubPage}/>
            <Route exact path='/clubs/:clubId/:teamId' component={TeamPage}/>
            <Route exact path='/statistieken' component={StatisticsPage}/>
            <Redirect to='/activiteiten'/>
          </Switch>
        )
      }
      case 'clubadmin': {
        return (
          <Switch>
            <Route exact path='/activiteiten' component={ActivitiesPage}/>
            <Route exact path='/club' component={ClubPage}/>
            <Route exact path='/ploeg/:teamId' component={TeamPage}/>
            <Route exact path='/statistieken' component={StatisticsPage}/>
            <Redirect to='/activiteiten'/>
          </Switch>
        )
      }

      case 'coach': {
        return (
          <Switch>
            <Route exact path='/activiteiten' component={ActivitiesPage}/>
            <Route exact path='/ploeg' component={ClubPage}/>
            <Route exact path='/ploeg/:teamId' component={TeamPage}/>
            <Route exact path='/statistieken' component={StatisticsPage}/>
            <Redirect to='/activiteiten'/>
          </Switch>
        )
      }

      default: {
        throw (new Error(`error: user role "${me.role}" not supported`))
      }
    }
  }

  return (
    <BrowserRouter>
      <ClubColorDefinitions club={me.club}/>
      <div className={classes.root}>
        <Header me={me}/>
        <div className={classes.page} style={{ marginTop: headerHeight }}>
          {renderRouter()}
        </div>
      </div>
    </BrowserRouter>
  )
}

const mapStateToProps = ({ appState }) => ({
  headerHeight: appState.headerHeight
})

export default connect(mapStateToProps)(PrivateRouter)
