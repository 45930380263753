const reducer = (state, action) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case 'setFilterState': {
      return action.filterState
    }
    case 'setSeasonFilter': {
      newState.seasonFilter = action.seasonFilter
      return newState
    }
    case 'setClubsFilter': {
      newState.clubsFilter = action.clubsFilter
      return newState
    }
    case 'setTeamsFilter': {
      newState.teamsFilter = action.teamsFilter
      return newState
    }
    case 'setUserFilter': {
      newState.userFilter = action.userFilter
      return newState
    }
    default: {
      return newState
    }
  }
}

export default reducer
