import React, { useCallback, useEffect, useState } from 'react'
import classes from './TeamPage.module.scss'
import i18next from '../../translations'
import { Helmet } from 'react-helmet'
import Button from '../../components/Button/Button'
import api from '../../api'
import Surface from '../../components/Surface/Surface'
import Input from '../../components/Input/Input'
import Popup from '../../components/Popup/Popup'
import CreateCoachForm from '../../components/CreateCoachForm/CreateCoachForm'
import NavList from '../../components/NavList/NavList'
import SidePanel from '../../components/SidePanel/SidePanel'
import UserInfoSidePanel from '../../components/UserInfoSidePanel/UserInfoSidePanel'
import BackButton from '../../components/BackButton/BackButton'
import Page from '../Page/Page'
import { NavLink } from 'react-router-dom'
import HoverActions from '../../components/HoverActions/HoverActions'
import IconButton from '../../components/IconButton/IconButton'
import Trash from '../../components/svg/Trash'
import DeleteCoachForm from '../../components/DeleteCoachForm/DeleteCoachForm'
import getCurrentSeason from '../../utils/getCurrentSeason'
import jsonToCSV from '../../utils/jsonToCSV'
import downloadCSV from '../../utils/downloadCSV'
import RenameTeamForm from '../../components/RenameTeamForm/RenameTeamForm'
import DeleteTeamForm from '../../components/DeleteTeamForm/DeleteTeamForm'
import { useHistory } from 'react-router'
import useMe from '../../customHooks/useMe'
import useIsMounted from '../../customHooks/useIsMounted'

const TeamPage = (props) => {
  const {
    match
  } = props

  const {
    teamId
  } = match.params

  const { me, isAdmin, isClubAdmin, isCoach } = useMe()

  const clubId = isAdmin
    ? match.params.clubId
    : me.club._id

  const history = useHistory()
  const isMounted = useIsMounted()
  const [currentSeason] = useState(getCurrentSeason())
  const [club, setClub] = useState(null)
  const [team, setTeam] = useState(null)
  const [coaches, setCoaches] = useState([])
  const [seasonAttendees, setSeasonAttendees] = useState([])
  const [seasonAttendeesSearchString, setSeasonAttendeesSearchString] = useState(null)
  const [sidePanelProps, setSidePanelProps] = useState(null)
  const [showCreateCoachModal, setShowCreateCoachModal] = useState(false)
  const [showDeleteCoachModal, setShowDeleteCoachModal] = useState(false)
  const [showRenameModal, setShowRenameModal] = useState(false)
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false)

  const getClubPath = useCallback(() => {
    if (isAdmin) {
      return '/clubs'
    }
    if (isClubAdmin) {
      return '/club'
    }
    return '/ploeg'
  }, [isAdmin, isClubAdmin])

  const getTeam = useCallback(() => {
    setShowCreateCoachModal(false)
    setShowDeleteCoachModal(false)
    setShowRenameModal(false)
    setShowDeleteTeamModal(false)
    api.club.getClub(clubId)
      .then((club) => {
        setClub(club)
        const team = club.teams.find(({ _id }) => _id === teamId)
        if (team) {
          setTeam(team)
        } else {
          history.replace(isAdmin ? `${getClubPath()}/${clubId}` : getClubPath())
        }
      })
      .then(() => {
        const filter = {
          club: clubId,
          team: teamId,
          role: 'coach,clubadmin'
        }
        api.user.getUsers(filter)
          .then(setCoaches)
      })
    api.activity.getSeasonAttendees(teamId, currentSeason)
      .then(setSeasonAttendees)
  }, [clubId, currentSeason, getClubPath, history, isAdmin, teamId])

  const downloadSizes = () => {
    const filter = {
      club: clubId,
      team: teamId,
      role: 'player,coach,clubadmin'
    }
    api.user.getUsers(filter)
      .then((users) => {
        const seasonStart = getCurrentSeason()
        const seasonEnd = seasonStart + 1
        const title = `${club.name}: ${team.name} (${seasonStart} - ${seasonEnd})`
        const sizeDataJSON = users.map((user) => ({
          Name: user.name,
          'T-shirtsize': (user.shirtSize ?? '?').toUpperCase(),
          'Shoe size': user.shoeSize ?? '?',
          'Activity Score': user.activityScore
        }))
        const sizeDataCSV = jsonToCSV(sizeDataJSON)
        downloadCSV(sizeDataCSV, title)
      })
  }

  const downloadTeamUserData = useCallback(() => {
    if (club?.name && team?.name) {
      const filter = {
        club: clubId,
        team: teamId,
        role: 'player,coach,clubadmin'
      }
      api.user.getUsers(filter).then((users) => {
        if (isMounted()) {
          const title = `${club.name} - ${team.name} (user data)`
          const teamUserDataJSON = users.map((user) => ({
            Name: user.name,
            Role: user.role,
            'Birth Year': user.birthYear ?? '?',
            'T-shirtsize': (user.shirtSize ?? '?').toUpperCase(),
            'Shoe size': user.shoeSize ?? '?',
            'Activity Score': user.activityScore ?? 0
          }))
          const teamUserDataCSV = jsonToCSV(teamUserDataJSON)
          downloadCSV(teamUserDataCSV, title)
        }
      })
    }
  }, [club?.name, clubId, isMounted, team?.name, teamId])

  const downloadTeamActivityData = useCallback(() => {
    if (club?.name && team?.name) {
      const filter = {
        club: clubId,
        team: teamId
      }
      api.activity.getActivities(filter).then((activities) => {
        if (isMounted()) {
          const title = `${club.name} - ${team.name} (activity data)`
          const maxAttendingTeams = activities.reduce((maxAttendingTeams, activity) => {
            return Math.max(maxAttendingTeams, activity.attendingTeams.length)
          }, 0)
          const teamActivityDataJSON = activities.map((activity) => {
            const activityData = {
              Type: activity.activityType,
              Date: activity.date,
              Description: activity.description ?? '',
              Comment: activity.comment ?? ''
            }
            const emptyTeam = { team: { name: '' }, attendees: { length: '' }, coaches: { length: '' } }
            for (let index = 0; index < maxAttendingTeams; index++) {
              const attendingTeam = activity.attendingTeams?.[index] ?? emptyTeam
              const displayIndex = index + 1
              activityData[`Attending Team ${displayIndex} Name`] = attendingTeam.team.name
              activityData[`Attending Team ${displayIndex} Attendees`] = attendingTeam.attendees.length
              activityData[`Attending Team ${displayIndex} Coaches`] = attendingTeam.coaches.length
            }
            return activityData
          })
          const teamActivityDataCSV = jsonToCSV(teamActivityDataJSON)
          downloadCSV(teamActivityDataCSV, title)
        }
      })
    }
  }, [club?.name, clubId, isMounted, team?.name, teamId])

  useEffect(() => {
    getTeam()
  }, [])

  if (!(club && team)) {
    return null
  }

  const seasonAttendeesNavList = seasonAttendees
    .filter(({ name }) => {
      if (!seasonAttendeesSearchString) {
        return true
      }
      const regex = new RegExp(seasonAttendeesSearchString, 'i')
      return regex.test(name)
    })
    .map(({ _id, name }) => {
      const sidePanelProps = {
        onClose: () => setSidePanelProps(null),
        component: UserInfoSidePanel,
        userId: _id
      }
      return {
        content: name,
        to: () => setSidePanelProps(sidePanelProps)
      }
    })

  const coachesNavList = coaches.map((coach) => {
    const sidePanelProps = {
      onClose: () => setSidePanelProps(null),
      component: UserInfoSidePanel,
      userId: coach._id
    }
    return {
      content: isCoach
        ? coach.name
        : (
          <HoverActions
            content={
              <div className={classes.coachHoverActionsContainer}>
                {coach.name}
              </div>
            }
            actions={
              coaches.length > 1
                ? [
                  <IconButton
                    icon={Trash}
                    onClick={() => setShowDeleteCoachModal({ userId: coach._id, coachName: coach.name })}
                  />
                  ]
                : []
            }
          />
          ),
      to: () => setSidePanelProps(sidePanelProps)
    }
  })

  const teamActivitiesQuery = new URLSearchParams({
    teamsFilter: JSON.stringify([teamId])
  })

  return (
    <Page className={classes.root}>
      <Helmet>
        <title>Younited: Ploeg</title>
      </Helmet>
      {
        Boolean(sidePanelProps) && (
          <SidePanel {...sidePanelProps}/>
        )
      }
      {
        Boolean(showCreateCoachModal) && (
          <Popup
            title={i18next.t('actions:add_coach')}
            onClose={() => setShowCreateCoachModal(false)}
            body={
              <CreateCoachForm
                clubId={clubId}
                teamId={teamId}
                teamName={team.name}
                coaches={coaches}
                onCancel={() => setShowCreateCoachModal(false)}
                callback={getTeam}
              />
            }
          />
        )
      }
      {
        Boolean(showDeleteCoachModal) && (
          <Popup
            title={i18next.t('actions:delete_coach')}
            onClose={() => setShowDeleteCoachModal(false)}
            body={
              <DeleteCoachForm
                teamId={team._id}
                teamName={team.name}
                userId={showDeleteCoachModal.userId}
                coachName={showDeleteCoachModal.coachName}
                onCancel={() => setShowDeleteCoachModal(false)}
                callback={getTeam}
                isAdmin={isAdmin}
              />
            }
          />
        )
      }
      {
        Boolean(showRenameModal) && (
          <Popup
            title={i18next.t('actions:rename_team')}
            onClose={() => setShowRenameModal(false)}
            body={
              <RenameTeamForm
                clubId={club._id}
                teamId={team._id}
                teamName={team.name}
                onCancel={() => setShowRenameModal(false)}
                callback={getTeam}
              />
            }
          />
        )
      }
      {
        Boolean(showDeleteTeamModal) && (
          <Popup
            title={i18next.t('actions:delete_team')}
            onClose={() => setShowDeleteTeamModal(false)}
            body={
              <DeleteTeamForm
                clubId={club._id}
                clubName={club.name}
                teamId={team._id}
                teamName={team.name}
                onCancel={() => setShowDeleteTeamModal(false)}
                callback={getTeam}
              />
            }
          />
        )
      }
      <div className={classes.header}>
        <BackButton/>
        <div className={classes.name}>
          <div><NavLink to={getClubPath}>{club.name}</NavLink> - {team.name}</div>
          {
            Boolean(isAdmin || isClubAdmin) && (
              <div className={classes.buttons}>
                <Button
                  onClick={() => setShowRenameModal(true)}
                >{i18next.t('actions:rename_team')}</Button>
                <Button
                  onClick={() => setShowDeleteTeamModal(true)}
                >{i18next.t('actions:delete_team')}</Button>
              </div>
            )
          }
        </div>
      </div>
      <div className={classes.body}>
        <div>
          <Surface className={classes.surface}>
            <div className={classes.title}>
              <div className={classes.withSubTitle}>
                <div>{i18next.t('common:attendees')}</div>
                <div
                  className={classes.subTitle}>{i18next.t('common:season')} {String(currentSeason)} - {String(currentSeason + 1)}</div>
              </div>
              {
                isAdmin
                  ? <div className={classes.attendeesCounter}>{seasonAttendees.length}</div>
                  : <Input
                    value={seasonAttendeesSearchString}
                    onChange={({ target }) => setSeasonAttendeesSearchString(target.value)}
                    placeholder={i18next.t('actions:search')}
                  />
              }
            </div>
            {
              Boolean(!isAdmin) && (
                <div>
                  <NavList items={seasonAttendeesNavList}/>
                </div>
              )
            }
          </Surface>
        </div>
        <div>
          <Surface className={classes.surface}>
            <div className={classes.title}>
              <div>{i18next.t('common:coaches')}</div>
              {
                Boolean(!isCoach) && (
                  <Button theme="primary"
                          onClick={() => setShowCreateCoachModal(true)}>{i18next.t('actions:add_coach')}</Button>
                )
              }
            </div>
            <div>
              <NavList items={coachesNavList}/>
            </div>
          </Surface>
        </div>
        {
          Boolean(!isAdmin) && (
            <div className={classes.buttonsContainer}>
              <Button theme="primary">
                <NavLink style={{ textDecoration: 'unset' }} to={`/activiteiten/?${teamActivitiesQuery}`}>
                  {i18next.t('actions:show_activities')}
                </NavLink>
              </Button>

              <Button theme="primary" onClick={downloadSizes}>
                {i18next.t('actions:download_shoe_and_shirt_sizes')}
              </Button>

              <Button theme="primary" onClick={downloadTeamUserData}>
                {i18next.t('actions:download_team_user_data')}
              </Button>

              <Button theme="primary" onClick={downloadTeamActivityData}>
                {i18next.t('actions:download_team_activity_data')}
              </Button>
            </div>
          )
        }
      </div>
    </Page>
  )
}

export default TeamPage
